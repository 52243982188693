q
<template>
  <div @click="onClickOutside">
    <div id="googleIdentity" class="d-none"></div>
    <Loading v-if="$store.state.config.isLoading" />
    <div class="narasi-main">
      <Header @removeStrip="removeStrip()" />
      <Nuxt
        class="content"
        :class="{
          'with-strip': stripText,
          withoutHighlights: !$store.state.navbar.highlights.items.length,
        }"
      ></Nuxt>
      <Footer />
      <BottomStickyAds class="bottom-sticky-ads" />
      <SkyScraperLeft class="sky-scraper-ads" />
      <SkyScraperRight class="sky-scraper-ads" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Loading from "@/components/Loading";
import BottomStickyAds from "@/components/ads/homepage/BottomSticky.vue";
import SkyScraperLeft from "@/components/ads/SkyScraperLeft.vue";
import SkyScraperRight from "@/components/ads/SkyScraperRight.vue";
import config from "@/config";
import helper from "@/utils/helper";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide,
    Loading,
    BottomStickyAds,
    SkyScraperLeft,
    SkyScraperRight,
  },
  watch: {
    "$nuxt.isOffline": (isOffline) => {
      window.$nuxt.$toast.clear();
      if (isOffline) {
        window.$nuxt.$toast.error("Anda sedang offline. Periksa koneksi Anda");
      } else {
        window.$nuxt.$toast.success("Anda sudah tersambung kembali");
      }
    },
    $router: (isOffline) => {
      console.log("test");
    },
  },
  data() {
    return {
      showAutocomplete: false,
      canonical: null,
      scriptSeo: [],
      liveStreaming: null,
      stripText: false,
    };
  },
  jsonld() {
    return [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        url: "https://narasi.tv",
        logo: "https://narasi.tv/logo.png",
      },
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        url: "https://narasi.tv/",
        potentialAction: {
          "@type": "SearchAction",
          target: {
            "@type": "EntryPoint",
            urlTemplate: "https://narasi.tv/search?q={search_term_string}",
          },
          "query-input": "required name=search_term_string",
        },
      },
    ];
  },
  methods: {
    removeStrip() {
      this.stripText = false;
    },
    onClickOutside(event) {
      // remove picture in picture playback
      let videoPlayback = document.pictureInPictureElement;
      if (
        this.$route.name !== "slug" &&
        this.$route.name !== "playlist-category-slug" &&
        this.$route.name !== "live-slug"
      ) {
        if (videoPlayback) {
          videoPlayback.pause();
          document.exitPictureInPicture();
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getFullYear() {
      const date = new Date();
      return date.getFullYear();
    },
    login(payload) {
      this.$axios
        .$post(`${config.USER_SERVICE_API}/api/auth/sign-in`, payload)
        .then((localData) => {
          // this.$auth.setUser(localData.data);
          if (
            !localData.data.phoneNumber ||
            !localData.data.email ||
            localData.data.gender == null ||
            !localData.data.city ||
            !localData.data.birthdate ||
            !localData.data.occupation ||
            !localData.data.interests.length === 0
          ) {
            this.$toast.info(
              "Anda harus melengkapi data anda terlebih dahulu."
            );

            helper.setCookie("narasi-temp", JSON.stringify(localData), 1);

            window.location.href = this.$router.resolve(
              `/auth/complete-data?redirect=${this.$route.query.redirect}`
            ).href;
          } else {
            this.$auth.setUserToken(localData.credential.accessToken);
            this.$auth.setUser(localData.data);
          }

          if (this.$route.query.redirect) {
            window.location.href = this.$router.resolve({
              path: this.$route.query.redirect,
            }).href;
          }
          location.reload();
          this.$toast.success("Login menggunakan google berhasil.");
        })
        .catch((e) => {
          this.$toast.error("Email atau kata sandi yang anda masukkan salah.");
        });
    },
  },
  created() {
    if (process.server) {
      this.$store.commit("home/SET_DEVICE", this.$device.isDesktop);
    }
  },
  mounted() {
    const that = this;

    if (this.$store.state.storeUser.session === null) {
      this.$store.commit("storeUser/setSession", Date.now());
    }

    this.canonical = window.location.href;
    if (!this.$store.state.auth.loggedIn) {
      // window.onload = function () {
      //   google.accounts.id.initialize({
      //     client_id: process.env.CLIENT_ID,
      //     callback: (callback) => {
      //       let base64Url = callback.credential.split(".")[1];
      //       let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      //       let jsonPayload = decodeURIComponent(
      //         atob(base64)
      //           .split("")
      //           .map(function (c) {
      //             return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      //           })
      //           .join("")
      //       );

      //       const user = JSON.parse(jsonPayload);
      //       let payload = {
      //         email: user.email,
      //         password: "secret",
      //         provider: "google",
      //         type: "social-media",
      //         fullname: user.name,
      //         picture: user.picture,
      //         phoneNumber: null,
      //       };

      //       return that.login(payload);
      //     },
      //   });
      //   google.accounts.id.renderButton(
      //     document.getElementById("googleIdentity"),
      //     { theme: "outline", size: "large" } // customization attributes
      //   );
      //   google.accounts.id.prompt(); // also display the One Tap dialog
      // };
    }
  },
};
</script>

<style lang="scss" scoped>
.narasi-main {
  position: relative;
  @media only screen and (max-width: 1024px) {
    overflow: hidden;
  }
}
@media (max-width: 575.98px) {
  .narasi-navbar {
    .navbar-top {
      top: 0;
      width: 100vw;
    }
    .navbar-bottom {
      height: 0;
    }
  }
  .list-scroll {
    position: fixed !important;
    z-index: 1000;
    width: 100vw;
    left: 0;
    top: 56px !important;
    height: 40px;
    background-color: #34128c !important;
    li.list-inline-item {
      &.menu-active {
        padding: 3px 0 !important;
      }
    }
  }
  .search-and-profile {
    .d-sm-none {
      position: absolute;
      right: 65px;
      top: 7px;

      .bi-search {
        font-size: 15px !important;
      }
    }

    .text-login {
      margin-top: 10px;
    }
  }
}
.autocomplete-search {
  position: fixed;
  max-height: 200px;
  border-radius: 0px 0px 5px 5px;
  padding: 10px;
  top: 43px;
  background: white !important;
  width: 310px;
  ul.list-unstyled {
    li {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.content {
  margin-top: 95px;
  @media only screen and (max-width: 1024px) {
    margin-top: 137px;
  }
  &.withoutHighlights {
    margin-top: 58px;
    @media only screen and (max-width: 1024px) {
      margin-top: 102px;
    }
  }
  &.with-strip {
    margin-top: 142px;
  }
}
.narasi-navbar {
  top: 0;
  position: fixed;
  z-index: 1000;
}
.text-login {
  color: #ffe900;

  &:hover {
    color: #ffe900 !important;
  }
}

.list-scroll {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  li.list-inline-item {
    &.menu-active {
      border-bottom: 5px solid #ffe900;
    }
    padding: 7.5px 0;
    a {
      color: #fafafa;
      opacity: 0.5;
      &.Link-active ~ li.list-inline-item {
        color: #fafafa !important;
        opacity: unset;
      }
      &:hover {
        color: #fafafa !important;
        opacity: unset;
      }
    }
  }
}

.heigth-bar {
  height: 58px;
  background-color: #212121;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 6px;
}

.text-grey {
  color: #bdbdbd;
}

.search {
  padding: 10px 10px 10px 35px;
  background-color: white;
  height: 34px;
  width: 310px;
  border-radius: 5px;
  box-shadow: none !important;
  outline: none !important;
  color: black;
  border: unset;
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: url("../assets/img/x-circle.svg") no-repeat 50% 50%;
    filter: invert(8%) sepia(100%) saturate(6481%) hue-rotate(246deg)
      brightness(102%) contrast(0%);
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9e9e9e;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9e9e9e;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #9e9e9e;
  }
}
</style>
