<template>
  <div class="container container-error">
    <div class="error">
      <Icons name="error" />
      <h1 class="title">
        {{ error.statusCode === 404 ? "404" : "OPPS" }}
      </h1>
      <p
        class="message"
        v-html="
          error.statusCode === 404
            ? 'Kamu nggak tersesat kok Cuma salah alamat aja. Yuk, Balik lagi! <a href=\'/\'>Kembali Ke Beranda</a>'
            : error.message
        "
      ></p>
    </div>
    <div class="recomendation-article">
      <h2 class="title">Nikmati konten pilihan yang bisa kamu akses disini</h2>
      <p class="description">
        Konten rekomendasi untuk perluas wawasan dan update informasi harian
        kamu
      </p>
      <section id="terpopuler">
        <div class="list-card-terpopuler">
          <div
            v-swiper:mySwiperTerpopuler="swiperOptions.terpopuler"
            @transition-start="onSwiperSlideChange('terpopuler')"
          >
            <div class="swiper-wrapper">
              <ShimmerCardRecomendationError
                v-show="terpopuler.loading"
                v-for="(item, index) in 4"
                :key="index"
                class="swiper-slide"
              />
              <CardTwo
                :to="
                  item.isText
                    ? {
                        name: `read-channel-slug`,
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                      }
                "
                v-show="!terpopuler.loading"
                v-for="item in terpopuler.items"
                :key="item.id"
                :image="
                  item.thumbnail && item.thumbnail.medium
                    ? item.thumbnail.medium
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :duration="item.timeVideo ? item.timeVideo : '00:00'"
                :channel="item.channel.title"
                :channelSlug="item.channel.slug"
                :programSlug="
                  item.channel.program ? item.channel.program.slug : ''
                "
                :category="item.category ? item.category.title : ''"
                :categorySlug="item.category ? item.category.slug : ''"
                :title="item.title"
                :isText="item.isText"
                :isAdvertorial="item.isAdvertorial"
                class="swiper-slide"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CardTwo from "@/components/cards/CardTwo.vue";
import Icons from "@/components/Icons.vue";
import ShimmerCardRecomendationError from "@/components/shimmer/CardRecomendationError.vue";

export default {
  props: ["error"],
  components: {
    CardTwo,
    ShimmerCardRecomendationError,
    Icons,
  },
  data() {
    return {
      arrow: {
        terpopuler: {
          prev: true,
          next: false,
        },
      },

      swiperOptions: {
        terpopuler: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          watchSlidesVisibility: true,
          // navigation: {
          //   prevEl: ".prev-terpopuler",
          //   nextEl: ".next-terpopuler",
          // },
          breakpoints: {
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 2.2,
              spaceBetween: 8,
            },
          },
        },
      },
    };
  },
  methods: {
    reload() {
      location.reload();
    },
    async initData() {
      await this.$store.dispatch("home/getTerpopuler", {
        page: 1,
      });
    },
    onSwiperSlideChange(params) {
      this.arrow.terpopuler.next = this.mySwiperTerpopuler.isEnd;
      this.arrow.terpopuler.prev = this.mySwiperTerpopuler.isBeginning;
    },
  },
  head() {
    return {
      ...this.headReturn,
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      terpopuler: (state) => {
        return state.home.terpopuler;
      },
    }),
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20vh 0;
  @media only screen and (max-width: 1024px) {
    padding: 10vh 10px;
  }
  .recomendation-article {
    margin-top: 7vh;
    .title {
      color: #051c2c;
      font-size: 16px;
      font-weight: 500;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    .description {
      font-size: 12px;
      color: #616161;
      font-weight: 400;
    }
    #terpopuler {
      margin-top: 10px;
      .swiper-wrapper {
        height: 100% !important;
      }
    }
  }

  .error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    svg {
      width: 75px;
      height: 75px;
      margin-bottom: 32px;
      @media only screen and (max-width: 1024px) {
        width: 70px;
        height: 70px;
        height: fit-content;
      }
    }
    .title {
      font-size: 40px;
      font-weight: 700;
      color: #051c2c;
      margin-bottom: 24px;
    }
    .message {
      font-size: 24px;
      margin-bottom: 24px;
      width: 45%;
      text-align: left;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        width: 100%;
        font-size: 20px;
      }
      ::v-deep a {
        font-weight: 500;
        color: #4a25aa;
        text-decoration: underline !important;
        &:hover {
          color: #4a25aa !important;
        }
      }
    }
  }
}
</style>
