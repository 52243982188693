var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container container-error"},[_c('div',{staticClass:"error"},[_c('Icons',{attrs:{"name":"error"}}),_vm._v(" "),_c('h1',{staticClass:"title"},[_vm._v("\n      "+_vm._s(_vm.error.statusCode === 404 ? "404" : "OPPS")+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"message",domProps:{"innerHTML":_vm._s(
        _vm.error.statusCode === 404
          ? 'Kamu nggak tersesat kok Cuma salah alamat aja. Yuk, Balik lagi! <a href=\'/\'>Kembali Ke Beranda</a>'
          : _vm.error.message
      )}})],1),_vm._v(" "),_c('div',{staticClass:"recomendation-article"},[_c('h2',{staticClass:"title"},[_vm._v("Nikmati konten pilihan yang bisa kamu akses disini")]),_vm._v(" "),_c('p',{staticClass:"description"},[_vm._v("\n      Konten rekomendasi untuk perluas wawasan dan update informasi harian\n      kamu\n    ")]),_vm._v(" "),_c('section',{attrs:{"id":"terpopuler"}},[_c('div',{staticClass:"list-card-terpopuler"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperTerpopuler",value:(_vm.swiperOptions.terpopuler),expression:"swiperOptions.terpopuler",arg:"mySwiperTerpopuler"}],on:{"transition-start":function($event){return _vm.onSwiperSlideChange('terpopuler')}}},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((4),function(item,index){return _c('ShimmerCardRecomendationError',{directives:[{name:"show",rawName:"v-show",value:(_vm.terpopuler.loading),expression:"terpopuler.loading"}],key:index,staticClass:"swiper-slide"})}),_vm._v(" "),_vm._l((_vm.terpopuler.items),function(item){return _c('CardTwo',{directives:[{name:"show",rawName:"v-show",value:(!_vm.terpopuler.loading),expression:"!terpopuler.loading"}],key:item.id,staticClass:"swiper-slide",attrs:{"to":item.isText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                    },"image":item.thumbnail && item.thumbnail.medium
                  ? item.thumbnail.medium
                  : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '00:00',"channel":item.channel.title,"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial}})})],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }