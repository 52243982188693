<template>
  <nuxt />
</template>

<script>
export default {
  head() {
    return {
      script: [
        { src: "https://cdn.jwplayer.com/libraries/LulCuIg8.js" },
        { src: "https://ssl.p.jwpcdn.com/live/channel-embed.js" },
      ],
    };
  },
};
</script>
