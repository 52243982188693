<template>
  <div class="div">
    <Nuxt/>
    <footer class="footer">
      <div class="container">
        <div class="row mt-5">
          <div class="col-md-4">
            <div class="d-flex justify-content-between">
              <img src="@/assets/img/logo_version2.png" data-not-lazy width="161" height="47" class="img-fluid bottom-logo"
                   alt="Narasi TV">
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-center footer-link">
              <Link class="text-dark fs-14 fw-400" to="#">Press Release <i class="bi-dot"></i></Link>
              <Link class="text-dark fs-14 fw-400" to="#">Informasi Perusahaan <i class="bi-dot"></i></Link>
              <Link class="text-dark fs-14 fw-400" to="#">Privasi <i class="bi-dot"></i></Link>
              <Link class="text-dark fs-14 fw-400" to="#">Pedoman Liputan Media Siber <i class="bi-dot"></i>
              </Link>
              <Link class="text-dark fs-14 fw-400" to="#">Kontak Kami <i class="bi-dot"></i></Link>
              <Link class="text-dark fs-14 fw-400" to="/karir">Karir</Link>
              <div class="partner mt-3">
                <span class="fs-10 fw-400 text-muted">Our Partner</span>
                <ul class="list-inline mt-3">
                  <li class="list-inline-item">
                    <img src="@/assets/img/logo-gdp.png" data-not-lazy width="48" height="35" class="img-fluid"
                         alt="GDP">
                  </li>
                  <li class="list-inline-item">
                    <img src="@/assets/img/logo-gv.png" data-not-lazy width="76" height="14" class="img-fluid"
                         alt="GDP">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <ul class="list-inline text-center social-media">
              <li class="list-inline-item fs-24 text-primary"><i class="bi-instagram"></i></li>
              <li class="list-inline-item fs-24 text-primary"><i class="bi-twitter"></i></li>
              <li class="list-inline-item fs-24 text-primary"><i class="bi-facebook"></i></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright mt-3 d-flex justify-content-center align-items-center">
        <span class="text-white">&copy; {{ getFullYear() }} Narasi.tv | All rights reserved.</span>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 575.98px) {
    .footer {
      height: 306px;
      .footer-link {
        width: 191px;
        margin: 0 auto;
        margin-top: 30px;
        a {
          font-size: 10px !important;
          color: #757575 !important;
        }
        .partner {
          margin-top: 25px !important;
        }
      }
      .bottom-logo {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 82px;
        height: 24px;
      }
      .social-media {
        position: absolute;
        top: 40px;
        right: 30px;
      }
      .copyright {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
    .navbar-top {
      width: 100vw;
      position: fixed;
      z-index: 1000;
    }
    .list-scroll {
      position: fixed !important;
      z-index: 1000;
      width: 100vw;
      left: 0;
      top: 56px !important;
      height: 40px;
      background-color: #34128C !important;
    }
    .search-and-profile {
      .d-sm-none {
        position: absolute;
        right: 65px;
        top: 7px;

        .bi-search {
          font-size: 15px !important;
        }
      }

      .text-login {
        margin-top: 10px;
      }
    }
  }

  .text-login {
    color: #FFE900;

    &:hover {
      color: #FFE900 !important;
    }
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 0 !important;
    width: 100%;
    background-color: white;
    border-top: 1px solid #F2F4F5;

    .copyright {
      background-color: #34128C;
      height: 42px;
    }
  }

  .heigth-bar {
    height: 58px;
    background-color: #212121;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 6px;
  }

  .text-grey {
    color: #BDBDBD;
  }

  .search {
    padding: 10px 10px 10px 35px;
    background-color: white;
    height: 34px;
    width: 310px;
    border-radius: 5px;
    box-shadow: none !important;
    outline: none !important;
    color: black;
    border: unset;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      background: url("../assets/img/x-circle.svg") no-repeat 50% 50%;
      filter: invert(8%) sepia(100%) saturate(6481%) hue-rotate(246deg) brightness(102%) contrast(0%);
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9E9E9E;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9E9E9E;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #9E9E9E;
    }
  }
</style>

<script>
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

  export default {
    components: {
      Swiper, SwiperSlide
    },
    data() {
      return {
        canonical: null,
        scriptSeo: [],
      };
    },
    jsonld() {
      return [
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://narasi.tv",
          "logo": "https://narasi.tv/logo.png"
        },
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://narasi.tv/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://narasi.tv/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          },
        }  
      ]
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      getFullYear() {
        const date = new Date();
        return date.getFullYear();
      },
    },
    mounted() {
      if (this.$store.state.storeUser.session === null) {
        this.$store.commit("storeUser/setSession", Date.now());
      }

      this.canonical = window.location.href;
    },
  };
</script>
